<template>
  <v-expansion-panels dark class="my-6">
    <v-expansion-panel>
      <v-expansion-panel-header expand-icon="mdi-menu-down" class="py-3">
        {{ $t("changePassword") }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form
          v-model="isValid"
          ref="form"
          @submit.prevent="handleChangePassword"
        >
          <base-password-field
            required
            v-model="formData.password"
            :label="$t('newPassword')"
            :placeholder="$t('placeholderNewPassword')"
            :rules="rulesPassword"
          />
          <div class="d-flex justify-end">
            <base-btn type="submit" color="primary">{{
              $t("change")
            }}</base-btn>
          </div>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { userCtrl } from "@/controllers";
import { getErrorPayloadForSnackbar, rulesPassword } from "@/helpers";
import { useSnackbar } from "@/utils";
export default {
  name: "UserChangePassword",
  data: () => ({
    formData: { password: "" },
    isValid: false,
    rulesPassword,
  }),
  methods: {
    async handleChangePassword() {
      try {
        const isValid = await this.$refs.form.validate();
        if (!isValid) return;
        const { message } = await userCtrl.handleChangePassword({
          password: this.formData.password,
        });
        if (message) {
          const snackbar = useSnackbar();
          snackbar.dispatchOpenSnackbar(message);
        }
        await this.$refs.form.resetValidation();
        this.formData.password = "";
      } catch (error) {
        const errorPayload = getErrorPayloadForSnackbar(error);
        this.$store.commit("user/OPEN_SNACKBAR", errorPayload);
      }
    },
  },
};
</script>
